<template>
  <v-app-bar app flat :color="bgcolor">
    <slot name="icon">
      <v-icon
        :color="color || 'black'"
        @click="
          routename
            ? $router.go(-1)
            : customRoute
            ? $router.push(customRoute).catch(() => {})
            : emitforclose()
        "
      >
        mdi-arrow-left
      </v-icon>
    </slot>

    <v-spacer></v-spacer>

    <span class="text-md-body-1 font-weight-6">{{ title }}</span>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  props: ['bgcolor', 'color', 'hasDrawer', 'title', 'routename', 'pop', 'customRoute'],
  components: {
  },
};
</script>

<style></style>
